<template>
  <div class="terms">
    <header>
      <van-nav-bar
        :left-arrow="true"
        @click-left="onClickLeft"
        :title="$route.meta.title"
      ></van-nav-bar>
    </header>
    <transition name="auth-router" mode="out-in">
      <router-view class="router" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "terms",
  data() {
    return {};
  },
  beforeMount() {},
  methods: {
    onClickLeft() {
      let isPrivacy = JSON.parse(localStorage.getItem("isPrivacy")); //隐私政策
      if (!isPrivacy) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "/",
          name: "/",
          query: [],
        });
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "auth/register",
          name: "Register",
          query: [],
        });
      }
    },
  },
};
</script>

<style scoped>
header {
  height: auto;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
</style>
